import React, { useContext } from 'react';
import GoogleAnalitycs from 'components/atoms/GoogleAnalitycs';
// import Slider from 'components/atoms/Slider';
import { BottomModalContext } from 'context/context';
import DynamicTitle from '../../components/molecules/DynamicTitle/DynamicTitle';
import { GlobalContainer } from '../../styles/commons';
import { BottomRightModal, BottomRightModalCookies } from '../../components/common/Modal';
import {
    CompanyValuesSection,
    ContactsSection,
    MainSliderSection,
    MissionSection,
    // PublicationsSection,
    // RequestSection,
    ProductsSection,
} from '../../components/common/Section';

const HomePage = () => {
    const { isBottomModal, modalBottomClose } = useContext(BottomModalContext);
    const { isBottomCookiesModal, modalBottomCookiesClose } = useContext(BottomModalContext);
    return (
        <GlobalContainer>
            <DynamicTitle title="Главная" />
            <MainSliderSection />
            <ProductsSection />
            <CompanyValuesSection />
            <MissionSection />
            {/* <Slider /> */}
            {/* <PublicationsSection /> */}
            {/* <RequestSection /> */}
            {/* backgroundColor="#F0F0F0" */}
            <ContactsSection />
            {isBottomModal && !isBottomCookiesModal && <BottomRightModal onClose={modalBottomClose} />}
            {isBottomCookiesModal && <BottomRightModalCookies onClose={modalBottomCookiesClose} />}
            <GoogleAnalitycs />
        </GlobalContainer>
    );
};

export default HomePage;
