/* eslint-disable react/jsx-indent */
/* eslint-disable no-unused-vars */
import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Container } from 'styles/commons';
import _ from 'lodash';
import {
    GlobalSearchButtons,
    GlobalSearchInner,
    GlobalSearchInputWrapper,
    GlobalSearchWrapper,
    NewsBlock,
} from './styles';
import CloseGlobalSearch from './CloseGlobalSearch';
import GlobalSearchButton from './GlobalSearchButton';
import { useGlobalSearch } from '../../commons/listHooks';
import { ContainerFull, RowAlignCol, TotalCountText } from '../../styles/commons';
import { fetchSearchData } from '../../services/NewsService';
import { ProductsSearchSectionContainer } from './SearchSection/styles';
import SingleSearchProduct from './SearchSection/SingleSearchProduct';
import GlobalNotFoundBlock from './SearchSection/NotFoundBlocks/GlobalNotFound';
import { Button } from '../common/Button';

const normalizeCount = (number, wordsArr) => {
    const num = Math.abs(number);
    if (Number.isInteger(num)) {
        const options = [2, 0, 1, 1, 1, 2];
        return wordsArr[num % 100 > 4 && num % 100 < 20 ? 2 : options[num % 10 < 5 ? num % 10 : 5]];
    }
    return wordsArr[1];
};

export const GlobalSearch = ({ onClick }) => {
    const [activeIndex, setActiveIndex] = useState(2);
    const [searchData, setSearchData] = useState(null);
    const [globalNotFound, setGlobalNotFound] = useState({ state: false, index: -1 });
    const handleButtonClick = (index) => {
        setActiveIndex(index === activeIndex ? 1 : index);
    };
    const [loading, setLoading] = useState(false);
    const { renderSearch, searchValue, setSearchValue } = useGlobalSearch();

    const search = () => {
        if (!searchValue || searchValue.length === 0) return;

        setLoading(true);

        fetchSearchData({ term: searchValue, limit: 6 })
            .then((res) => res.data)
            .then(setSearchData)
            .then(() => setLoading(false));
    };

    useEffect(() => {
        search();
    }, [searchValue]);

    const [data, metadata] = useMemo(() => [searchData ? searchData.data : [], searchData ? searchData.metadata : {}], [
        searchData,
    ]);

    const totalCounts = useMemo(
        () => [
            metadata.all && metadata.all ? metadata.all.count : 0,
            metadata.products && metadata.products ? metadata.products.count : 0,
        ],
        [metadata],
    );

    return (
        <>
            <GlobalSearchWrapper>
                <ContainerFull>
                    <Container>
                        <GlobalSearchInner>
                            <GlobalSearchInputWrapper>{renderSearch({})}</GlobalSearchInputWrapper>
                            <CloseGlobalSearch onClick={onClick} />
                        </GlobalSearchInner>
                        {searchValue.length < 1 ? (
                            <></>
                        ) : (
                            <>
                                <GlobalSearchButtons>
                                    {/* <GlobalSearchButton
                                        index={1}
                                        activeIndex={activeIndex}
                                        onClick={handleButtonClick}
                                        title="Все"
                                        totalCount={totalCounts[0]}
                                    /> */}
                                    <GlobalSearchButton
                                        index={2}
                                        activeIndex={activeIndex}
                                        onClick={handleButtonClick}
                                        title="Продукты"
                                        totalCount={totalCounts[1]}
                                    />
                                </GlobalSearchButtons>
                                <NewsBlock>
                                    <Container>
                                        <RowAlignCol>
                                            {data.products !== undefined && data.products.length > 0 ? (
                                                <>
                                                    <TotalCountText>
                                                        {`Найдено ${totalCounts[1]} ${normalizeCount(totalCounts[1], [
                                                            'результат',
                                                            'результата',
                                                            'результатов',
                                                        ])}`}
                                                    </TotalCountText>
                                                    <ProductsSearchSectionContainer>
                                                        {data.products.map((item) => (
                                                            <SingleSearchProduct item={item} term={searchValue} />
                                                        ))}
                                                        {data.products.length < 3 ? (
                                                            <></>
                                                        ) : (
                                                            <Button
                                                                search
                                                                link={`/products?term=${searchValue}`}
                                                                title="Смотреть все"
                                                            />
                                                        )}
                                                    </ProductsSearchSectionContainer>
                                                </>
                                            ) : (
                                                <></>
                                            )}
                                            {(data.products !== undefined &&
                                                data.products.length === 0 &&
                                                activeIndex === 2) ||
                                            (data.all !== undefined &&
                                                data.all.products.length === 0 &&
                                                activeIndex === 1) ? (
                                                <GlobalNotFoundBlock state={{ state: true, index: activeIndex }} />
                                            ) : (
                                                <></>
                                            )}
                                        </RowAlignCol>
                                    </Container>
                                </NewsBlock>
                            </>
                        )}
                    </Container>
                </ContainerFull>
            </GlobalSearchWrapper>
        </>
    );
};

GlobalSearch.propTypes = {
    onClick: PropTypes.func.isRequired,
};

export default GlobalSearch;
