/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import 'swiper/swiper-bundle.min.css';
import './styles.css';
import {
    ProductsSectionSliderContainer,
    ProductsSectionSliderContainerInner,
    ProductsSectionSliderSlideTitle,
} from './ProductsSectionSlider.styles';
import { fetchAllCatalog } from '../../../services/NewsService';
import ProductsSectionSliderSkeleton from './ProductsSectionSliderSkeleton/ProductsSectionSliderSkeleton';

export default function ProductsSectionSlider() {
    const [loader, setLoader] = useState(true);
    const [catalog, setCatalog] = useState([]);

    useEffect(() => {
        const getAllCatalog = () => {
            fetchAllCatalog()
                .then((response) => {
                    setCatalog(response.data.catalog);
                    setLoader(false);
                })
                .catch((e) => {
                    console.error(e);
                });
        };
        getAllCatalog();
    }, []);

    let firstThreeProducts = [];
    let remainingProducts = [];

    if (catalog.length >= 6) {
        firstThreeProducts = catalog.filter((product) => product.id === 40 || product.id === 18 || product.id === 17);
        remainingProducts = catalog.filter((product) => product.id !== 40 && product.id !== 18 && product.id !== 17);
        remainingProducts.sort(() => Math.random() - 0.5); // Randomize the order of remaining products
        remainingProducts = remainingProducts.slice(0, 3); // Take the first 3 remaining products
    } else {
        firstThreeProducts = catalog;
    }

    const displayedProducts = [...firstThreeProducts, ...remainingProducts];

    return (
        <ProductsSectionSliderContainer>
            <ProductsSectionSliderContainerInner>
                <Swiper
                    modules={[Navigation]}
                    slidesPerView={3}
                    spaceBetween={20}
                    navigation={{
                        nextEl: '.products-section-slider-button-next',
                        prevEl: '.products-section-slider-button-prev',
                    }}
                    breakpoints={{
                        0: {
                            slidesPerView: 1.4,
                        },
                        481: {
                            slidesPerView: 2,
                        },
                        769: {
                            slidesPerView: 3,
                        },
                    }}
                >
                    {loader
                        ? [...new Array(6)].map((_, i) => (
                              <SwiperSlide key={i}>
                                  <ProductsSectionSliderSkeleton />
                              </SwiperSlide>
                          ))
                        : displayedProducts.map((product) => (
                              <SwiperSlide key={product.cpu}>
                                  <a href={`products/${product.cpu}`}>
                                      <img src={product.images[0]} alt={product.title} />
                                      <ProductsSectionSliderSlideTitle>{product.title}</ProductsSectionSliderSlideTitle>
                                  </a>
                              </SwiperSlide>
                          ))}
                </Swiper>
            </ProductsSectionSliderContainerInner>
            <div className="products-section-slider-button-prev">
                <svg xmlns="http://www.w3.org/2000/svg" width="2.4rem" height="2.4rem" viewBox="0 0 24 24" fill="none">
                    <path d="M15.975 2L17.75 3.775L9.525 12L17.75 20.225L15.975 22L5.975 12L15.975 2Z" fill="#FF7100" />
                </svg>
            </div>
            <div className="products-section-slider-button-next">
                <svg xmlns="http://www.w3.org/2000/svg" width="2.4rem" height="2.4rem" viewBox="0 0 24 24" fill="none">
                    <path d="M8.025 22L6.25 20.225L14.475 12L6.25 3.775L8.025 2L18.025 12L8.025 22Z" fill="#FF7100" />
                </svg>
            </div>
        </ProductsSectionSliderContainer>
    );
}
