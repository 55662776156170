import React from 'react';
import { Container } from 'styles/commons';
import { Copyright, FeaturesLinks } from './Footer.styles';
import { ContainerFull, FlexRow } from '../../../styles/commons';
import FooterContent from './FooterContent/FooterContent';
import Codex from '../../../assets/uploads/cb89e337ec2b793728862e2989fdc0de.pdf';

const Footer = () => {
    return (
        <ContainerFull backgroundColor="#D9DADB">
            <Container>
                <FooterContent />
                <FlexRow justifyContent="space-between">
                    <Copyright>
                        <p>{`Acino Pharma AG © ${new Date().getFullYear()}`}</p>
                        <FeaturesLinks>
                            <a href="/cookie-policy">Конфиденциальность</a>
                            <a href="/agreement" target="_blank" rel="noopener noreferrer">
                                Соглашение
                            </a>
                            <a href={Codex} target="_blank" rel="noopener noreferrer">
                                Кодекс корпоративной этики
                            </a>
                        </FeaturesLinks>
                    </Copyright>
                </FlexRow>
            </Container>
        </ContainerFull>
    );
};

export default Footer;
