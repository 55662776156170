import React from 'react';
import { content } from '../../../commons/content';
import BreadCrumbs from '../../../components/common/BreadCrumbs/BreadCrumbs';
import { GlobalContainer } from '../../../styles/commons';
import { HeaderSlide } from '../../../components/common/HeaderSlide';
import { CompanyInNumbers } from '../../../components/common/CompanyInNumbers';
import { SingleSlide } from '../../../components/common/SingleSlide';
import { CompanyValuesSection } from '../../../components/common/Section/CompanyValuesSection';
import DynamicTitle from '../../../components/molecules/DynamicTitle/DynamicTitle';
import ImgWeb from '../../../assets/images/OurMissionPage/OurMissionHeaderWeb.png';
import ImgTab from '../../../assets/images/OurMissionPage/OurMissionHeaderTab.png';
import ImgMob from '../../../assets/images/OurMissionPage/OurMissionHeaderMob.png';
import GoogleAnalitycs from '../../../components/atoms/GoogleAnalitycs';
import ImgLeft from '../../../assets/images/MissionSection/2.png';
import { ContactsSection } from '../../../components/common/Section';

export default function MissionPage() {
    return (
        <>
            <DynamicTitle title="Наши Ценности" />
            <BreadCrumbs colorDefault="#FFFFFF80" colorActive="#fff" />
            <GlobalContainer>
                <HeaderSlide
                    data={{
                        title: content.HEADER_SLIDE_MISSION_TITLE,
                        subtitle: content.HEADER_SLIDE_MISSION_SUBTITLE,
                        bgColor: 'linear-gradient(74.77deg, #FF7100 9.99%, #F3CA37 89.98%)',
                    }}
                    // button={{
                    //     type: 'button',
                    //     text: content.HEADER_SLIDE_CAREER_BUTTON,
                    //     styles: { bgColor: 'transparent', border: '0.2rem solid #fff' },
                    // }}
                    images={{ web: ImgWeb, tab: ImgTab, mob: ImgMob }}
                />
                <CompanyInNumbers />
                <SingleSlide
                    imgLeft={ImgLeft}
                    preTitle={content.VALUES_SECTION_PRETITLE}
                    title={content.VALUES_SECTION_TITLE1}
                    text={content.VALUES_SECTION_TEXT1}
                    signature={content.VALUES_SECTION_SIGNATURE1}
                    // buttonText={content.MISSION_SECTION_BUTTON1}
                    // buttonLink="/our-values"
                    backgroundColor="#f0f0f0"
                />
                <CompanyValuesSection paddingBottom="12rem" />
                <ContactsSection backgroundColor="#F0F0F0" />
                <GoogleAnalitycs />
            </GlobalContainer>
        </>
    );
}
