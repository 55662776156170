/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { fetchCatalogId } from 'services/NewsService';
import GoogleAnalitycs from 'components/atoms/GoogleAnalitycs';
import { content } from 'commons/content';
import tabletka_button from 'assets/images/tabletka_button.png';
import adel_button from 'assets/images/adel_button.png';
import by103 from 'assets/images/103by.png';
import planeta from 'assets/images/apteka.jpg';
import { Redirect } from 'react-router-dom';
import {
    ProductContent,
    PriceBlock,
    ButtonImg,
    PriceText,
    ProductItemPageContent,
    ProductItemPageSliderContainer,
    ProductItemPageTitle,
    ProductItemPageText,
} from './ProductItemPage.styles';
import ImageAlt from '../../../components/HOC/ImageAlt/ImageAlt';
import DynamicTitle from '../../../components/molecules/DynamicTitle/DynamicTitle';
import { BreadCrumbs } from '../../../components/common/BreadCrumbs';
import { Container, ContainerFull, FlexRow, GlobalContainer } from '../../../styles/commons';
import ProductItemSlider from '../../../components/common/ProductItemSlider/ProductItemSlider';
import { DownloadPdfButton } from '../../../components/common/DownloadPdfButton';
import ProductItemPageSkeleton from './ProductItemPageSkeleton/ProductItemPageSkeleton';

const ProductItemPage = (props) => {
    const [loader, setLoader] = useState(true);
    const [catalog, setCatalog] = useState([]);
    const [catalogImg, setCatalogImg] = useState();
    const [error, setError] = useState(false);

    const getCatalog = (id) => {
        fetchCatalogId(id)
            .then((response) => {
                // const val = response.data.catalog.map((item) => item.images);
                setCatalog(response.data.catalog[0]);
                setCatalogImg({ images: response.data.catalog[0].images });
                setLoader(false);
            })
            .catch((e) => {
                // eslint-disable-next-line no-unused-expressions
                setError(true);
                console.error(e);
            });
    };
    useEffect(() => {
        const idCatalog = props.match.params.catalogId;
        getCatalog(idCatalog);
    }, [props.match.params.catalogId]);

    return (
        <>
            <DynamicTitle
                title={catalog.title}
                description={catalog && catalog.description}
                keywords={catalog && catalog.keywords}
            />
            {catalog ? <BreadCrumbs value={catalog.title} /> : <BreadCrumbs />}
            {error && <Redirect to="/error" />}
            <GlobalContainer>
                <ContainerFull paddingBottom="12rem">
                    <Container>
                        <FlexRow>
                            <ProductItemPageContent>
                                {loader ? (
                                    <ProductItemPageSkeleton />
                                ) : (
                                    <>
                                        <ProductItemPageSliderContainer>
                                            <ProductItemSlider images={catalogImg.images} />
                                        </ProductItemPageSliderContainer>
                                        <ProductContent>
                                            <ProductItemPageTitle>{catalog.title}</ProductItemPageTitle>
                                            <ProductItemPageText
                                                dangerouslySetInnerHTML={{
                                                    __html: catalog.text,
                                                }}
                                            />
                                            {catalog.pdf && <DownloadPdfButton link={catalog.pdf} />}
                                            {catalog.link_tabletka && (
                                                <PriceBlock>
                                                    <PriceText>{content.CATALOG_PRICE_PHARM}</PriceText>
                                                    <ButtonImg
                                                        href={catalog.link_tabletka}
                                                        rel="noreferrer noopener"
                                                        target="_blank"
                                                    >
                                                        <ImageAlt
                                                            src={tabletka_button}
                                                            alt={tabletka_button}
                                                            height="60px"
                                                        />
                                                    </ButtonImg>
                                                    <ButtonImg
                                                        href={catalog.link_103}
                                                        rel="noreferrer noopener"
                                                        target="_blank"
                                                    >
                                                        <ImageAlt src={by103} alt={by103} height="60px" />
                                                    </ButtonImg>
                                                </PriceBlock>
                                            )}
                                            <PriceBlock>
                                                {catalog.link_adel || catalog.link_planeta ? (
                                                    <PriceText>{content.CATALOG_ORDER_PHARM}</PriceText>
                                                ) : null}
                                                {catalog.link_adel && (
                                                    <ButtonImg
                                                        href={catalog.link_adel}
                                                        rel="noreferrer noopener"
                                                        target="_blank"
                                                    >
                                                        <ImageAlt src={adel_button} alt={adel_button} height="60px" />
                                                    </ButtonImg>
                                                )}
                                                {catalog.link_planeta && (
                                                    <ButtonImg
                                                        href={catalog.link_planeta}
                                                        rel="noreferrer noopener"
                                                        target="_blank"
                                                    >
                                                        <ImageAlt src={planeta} alt={planeta} height="60px" />
                                                    </ButtonImg>
                                                )}
                                            </PriceBlock>
                                        </ProductContent>
                                    </>
                                )}
                            </ProductItemPageContent>
                        </FlexRow>
                    </Container>
                </ContainerFull>
                <GoogleAnalitycs />
            </GlobalContainer>
        </>
    );
};

export default ProductItemPage;
