import React from 'react';
import { content } from 'commons/content';
import { PropTypes } from 'prop-types';
// import { emailLink, phoneLink, adressLink } from 'commons/constants';
import { ContainerFull, FlexColumn, Container, FlexRow } from '../../../../styles/commons';
import Map from '../../../../assets/images/map.png';
import {
    ContactsTitle,
    ContactsSubtitle,
    ContactsContentTitle,
    ContactsContentSubtitle,
    ImageBlock2,
    // Detail,
    // Address,
    // Phone,
    // Fax,
    // Email,
    // InfoBlock,
    ContactsContentWrapper,
    ContactsBlock,
    Img,
    ContactsContentSubtitleDesc,
    // FillForm,
    // Button,
} from './ContactsSection.styles';
import { adressLink, emailLink, phoneLink, isMobile } from '../../../../commons/constants';

export const ContactsSection = ({ backgroundColor }) => {
    return (
        <ContainerFull backgroundColor={backgroundColor} id="contacts" paddingBottom="12rem">
            <Container>
                <FlexRow alignItems="center" justifyContent="space-between">
                    <FlexColumn alignItems="flex-start" justifyContent="flex-start">
                        <ContactsTitle>{content.CONTACTS_TITLE}</ContactsTitle>
                        <FlexColumn alignItems="flex-start" justifyContent="flex-start">
                            <ContactsSubtitle>{content.CONTACTS_SUBTITLE}</ContactsSubtitle>
                            <ContactsContentWrapper>
                                <FlexRow justifyContent={(isMobile && 'flex-start') || 'center'}>
                                    <ContactsBlock>
                                        <ContactsContentTitle>{content.CONTACTS_CONTENT_TITLE3}</ContactsContentTitle>
                                        <ContactsContentSubtitle href={phoneLink.PHONE_LINK}>
                                            {content.CONTACTS_CONTENT_SUBTITLE3}
                                        </ContactsContentSubtitle>
                                    </ContactsBlock>
                                    <ContactsBlock>
                                        <ContactsContentTitle>{content.CONTACTS_CONTENT_TITLE2}</ContactsContentTitle>
                                        <ContactsContentSubtitle href={emailLink.OFFER_EMAIL_LINK}>
                                            {content.CONTACTS_CONTENT_SUBTITLE2}
                                        </ContactsContentSubtitle>
                                    </ContactsBlock>
                                </FlexRow>
                                <FlexRow justifyContent="flex-start">
                                    <ContactsBlock>
                                        <ContactsContentTitle>{content.CONTACTS_CONTENT_TITLE1}</ContactsContentTitle>
                                        <ContactsContentSubtitle href={adressLink.ADRESS_LINK} target="_blank">
                                            {content.CONTACTS_CONTENT_SUBTITLE1}
                                        </ContactsContentSubtitle>
                                    </ContactsBlock>
                                </FlexRow>
                            </ContactsContentWrapper>
                        </FlexColumn>
                        <FlexColumn alignItems="flex-start" justifyContent="flex-start">
                            <ContactsSubtitle>{content.CONTACTS_SUBTITLE2}</ContactsSubtitle>
                            <ContactsContentSubtitleDesc>{content.CONTACTS_SUBTITLE3}</ContactsContentSubtitleDesc>
                            <ContactsContentWrapper>
                                <FlexRow justifyContent={(isMobile && 'flex-start') || 'center'}>
                                    <ContactsBlock>
                                        <ContactsContentTitle>{content.CONTACTS_CONTENT_TITLE3}</ContactsContentTitle>
                                        <ContactsContentSubtitle href={adressLink.ADRESS_LINK} target="_blank">
                                            {content.CONTACTS_CONTENT_SUBTITLE4}
                                        </ContactsContentSubtitle>
                                    </ContactsBlock>
                                    <ContactsBlock>
                                        <ContactsContentTitle>{content.CONTACTS_CONTENT_TITLE2}</ContactsContentTitle>
                                        <ContactsContentSubtitle href={emailLink.OFFER_EMAIL_LINK}>
                                            {content.CONTACTS_CONTENT_SUBTITLE5}
                                        </ContactsContentSubtitle>
                                    </ContactsBlock>
                                </FlexRow>
                            </ContactsContentWrapper>
                        </FlexColumn>
                    </FlexColumn>
                    <ImageBlock2>
                        <Img src={Map} />
                    </ImageBlock2>
                </FlexRow>
                {/* <Detail>{content.CONTACTS_DETAIL}</Detail>
                        <Address href={adressLink.ADRESS_LINK}>{content.ADRESS}</Address>
                        <Phone href={phoneLink.PHONE_LINK}>{content.PHONE}</Phone>
                        <Fax>{content.FAX}</Fax>
                        <Email href={emailLink.OFFER_EMAIL_LINK}>{content.EMAIL}</Email>
                        <FillForm dangerouslySetInnerHTML={{ __html: content.CONTACTS_FILL_FORM }} />
                        <Button href="https://acino-request-forms-qual.azurewebsites.net/request">
                            {content.CONTACTS_FILL_FORM_BUTTON}
                        </Button> */}
            </Container>
        </ContainerFull>
    );
};

ContactsSection.defaultProps = {
    backgroundColor: undefined,
};

ContactsSection.propTypes = {
    backgroundColor: PropTypes.string,
};

export default ContactsSection;
