/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';

const ImgWithAlt = ({ src, alt, ...props }) => {
    let editableAlt;
    if (!alt) {
        editableAlt = '';
    } else {
        editableAlt = alt.split('/').pop().split('.').shift();
    }
    const imageDescription = `${editableAlt}`;
    return src ? <img src={src} alt={imageDescription} {...props} /> : <div />;
};

ImgWithAlt.propTypes = {
    src: PropTypes.string,
    alt: PropTypes.string,
};
ImgWithAlt.defaultProps = {
    src: '',
    alt: '',
};

export default ImgWithAlt;
