export const ru = {
    // Header Nav Menu
    MENU_HEADER_HOME: 'Главная',
    MENU_HEADER_ABOUT: 'О компании',
    MENU_HEADER_PARTNERS_SUBHEADER_MISSION: 'Наши ценности',
    MENU_HEADER_PARTNERS_SUBHEADER_HISTORY: 'Наша история',
    MENU_HEADER_PARTNERS_SUBHEADER_TEAM: 'Команда',
    MENU_HEADER_PARTNERS_SUBHEADER_CAREER: 'Карьера',
    MENU_HEADER_DRUGS: 'Продукты',
    MENU_HEADER_PARTNERS: 'Партнерам',
    // MENU_HEADER_PROMOHUB: 'Промохаб',
    MENU_HEADER_SUBSCRIPTION: 'Подписка',
    MENU_HEADER_TEAM: 'Команда',
    MENU_HEADER_CONTACTS: 'Контакты',
    // Header Nav Country
    SELECT_COUNTRY_BELARUS: 'Belarus',
    SELECT_COUNTRY_GLOBAL: 'Global',
    SELECT_COUNTRY_RUSSIA: 'Russia',
    SELECT_COUNTRY_UKRAINE: 'Ukraine',
    SELECT_COUNTRY_KAZAKHSTAN: 'Kazakhstan',
    // Home Sliders
    // Home Slide 1
    SLIDER_HOME_TITLE1: 'Acino Belarus',
    SLIDER_HOME_SUBTITLE1:
        'Белорусское подразделение международной фармацевтической группы Acino со штаб-квартирой в Цюрихе, Швейцария.',
    // Home Slide 2
    SLIDER_HOME_TITLE2: '29 сентября - Всемирный день сердца',
    SLIDER_HOME_SUBTITLE2:
        'Фармацевтическая компания Acino подписала соглашение о сотрудничестве со Всемирной федерацией сердца в качестве официального генерального спонсора.',
    SLIDER_HOME_TITLE3: 'ADQ укрепляет свой портфель',
    // Home Slide 3
    SLIDER_HOME_SUBTITLE3:
        'На сегодняшний день Acino станет крупнейшим приобретением компании ADQ в сфере здравоохранения и фармацевтики',
    // Home Slide 4
    SLIDER_HOME_TITLE4: 'Merz Therapeutics и ACINO подписали  соглашение',
    SLIDER_HOME_SUBTITLE4:
        'ACINO будет заниматься продвижением и коммерциализацией продуктов Merz Therapeutics в таких терапевтических направлениях.',
    // Home Slide 5
    SLIDER_HOME_TITLE5: 'Acino Беларусь - проект года',
    SLIDER_HOME_SUBTITLE5:
        'Мы рады выиграть Проект года в области корпоративной социальной ответственности на конкурсе Eurasian Pharma Awards.',
    // Home Slide 6
    SLIDER_HOME_TITLE6: 'Acino. Всемирная федерация сердца',
    SLIDER_HOME_SUBTITLE6:
        'Acino поддерживает Всемирную федерацию сердца в повышении и осведомленности о сердечно-сосудистых заболеваниях (ССЗ) как основной причине смерти пациентов во всем мире. ',
    SLIDER_HOME_BUTTON: 'Подробнее',
    // Home Slide 7
    SLIDER_HOME_TITLE7: 'Определение риска развития рака молочной железы',
    SLIDER_HOME_SUBTITLE7: 'Пройдите тест на определение риска возникновения рака молочной железы',
    SLIDER_HOME_BUTTON_TEST: 'Пройти тест',
    // Home ProductsSectionSlider Button
    PRODUCTS_SECTION_BUTTON: 'Смотреть все',
    // Home Company Values Content
    COMPANY_VALUES_SECTION_TITLE1: 'Доверие',
    COMPANY_VALUES_SECTION_SUBTITLE1:
        'Твердо верить в надежность, истину и выполнять свои обязательства. Выражать уверенность. Быть верным. Действовать как командный игрок. Быть искренним.',
    COMPANY_VALUES_SECTION_TITLE2: 'Приверженность',
    COMPANY_VALUES_SECTION_SUBTITLE2:
        'Ориентироваться на цель и результат. Выполнять обещание и быть ответственным. Проявлять личную вовлеченность. Достигать большего. Действовать, как владелец бизнеса.',
    COMPANY_VALUES_SECTION_TITLE3: 'Отвага',
    COMPANY_VALUES_SECTION_SUBTITLE3:
        'Ставить амбициозны е цели и достигать их. Быть готовым к изменениям и выходить из зоны комфорта. Отстаивать свое мнение. Принимать вызовы. Рисковать.',
    COMPANY_VALUES_SECTION_TITLE4: 'Эмпатия',
    COMPANY_VALUES_SECTION_SUBTITLE4:
        'Быть открытым и проявлять уважение. Понимать и поддерживать других. Помогать и признавать вклад каждого. Слушать и слышать.',
    // Home Mission Content
    // Slide 2
    MISSION_SECTION_TITLE2: 'Видение',
    MISSION_SECTION_TEXT2: 'Быстрый рост и поддержка пациентов на рынках развивающихся стран',
    MISSION_SECTION_SIGNATURE2: '',
    MISSION_SECTION_BUTTON2: 'Подробнее',
    // Slide 3
    MISSION_SECTION_TITLE3: 'Ценности',
    MISSION_SECTION_OVERHEAD3_1: 'Доверие:',
    MISSION_SECTION_TEXT3_1:
        'Твердо верить в надежность, истину и выполнять свои обязательства. Выражать уверенность. Быть верным. Действовать как командный игрок. Быть искренним.',
    MISSION_SECTION_OVERHEAD3_2: 'Приверженность:',
    MISSION_SECTION_TEXT3_2:
        'Ориентироваться на цель и результат. Выполнять обещание и быть ответственным. Проявлять личную вовлеченность. Достигать большего. Действовать, как владелец бизнеса. Фокусироваться на потребностях клиентов и пациентов.',
    MISSION_SECTION_SIGNATURE3: '',
    MISSION_SECTION_BUTTON3: 'Подробнее',
    // Slide 4
    MISSION_SECTION_TITLE4: 'Наша история',
    MISSION_SECTION_TEXT4:
        'Истоки компании Acino, которая до 2008 года называлась «Schweizerhall», относятся к 1836 году, когда компания занималась соляным бизнессом неподалеку от Базеля. Впоследствии компания расширила сферу деятельности и выступила одновременно в качестве производителя и дистрибьютора, дополнив свой портфель высокотехнологичными продуктами, включая специальные химические продукты, такие как корма, удобрения и активные фармацевтические ингредиенты.',
    MISSION_SECTION_BUTTON4: 'Подробнее',
    // Publications section
    PUBLICATIONS_SECTION_TITLE: 'Последние публикации',
    PUBLICATIONS_SECTION_BUTTON: 'Смотреть все',
    OUR_MISSION_PAGE_TITLE1: 'Acino Belarus',
    OUR_MISSION_PAGE_SUBTITLE1:
        'Белорусское подразделение международной фармацевтической группы Acino со штаб-квартирой в Цюрихе, Швейцария.',
    OUR_MISSION_PAGE_BUTTON: 'Подробнее',
    // CompanyInNumbers section
    COMPANY_IN_NUMBERS_TITLE: 'Компания в цифрах (Кластер БелАГА)',
    COMPANY_IN_NUMBERS_ITEM_TITLE1: '4',
    COMPANY_IN_NUMBERS_ITEM_TEXT1: 'Международных офисов ',
    COMPANY_IN_NUMBERS_ITEM_TITLE2: '88 ',
    COMPANY_IN_NUMBERS_ITEM_TEXT2: 'Сотрудников в компании',
    COMPANY_IN_NUMBERS_ITEM_TITLE3: '37',
    COMPANY_IN_NUMBERS_ITEM_TEXT3: 'Продукта в портфеле',
    // ourMission page
    HEADER_SLIDE_MISSION_TITLE: 'Acino Belarus',
    HEADER_SLIDE_MISSION_SUBTITLE:
        'Белорусское подразделение международной фармацевтической группы Acino со штаб-квартирой в Цюрихе, Швейцария.',
    HEADER_SLIDE_MISSION_BUTTON: 'Подробнее',
    VALUES_SECTION_PRETITLE: '',
    VALUES_SECTION_TITLE1: 'Видение',
    VALUES_SECTION_TEXT1: 'Быстрый рост и поддержка пациентов на рынках развивающихся стран',
    VALUES_SECTION_SIGNATURE1: '',
    // OurHistory page
    HEADER_SLIDE_HISTORY_TITLE: 'Наша история',
    HEADER_SLIDE_HISTORY_SUBTITLE: 'Доказанный опытом и проложенный временем путь',
    HEADER_SLIDE_HISTORY_BUTTON: 'Подробнее',
    HISTORY_SLIDE_TITLE: 'Наша история',
    HISTORY_SLIDE_TEXT: [
        'История компании Acino, которая до 2008 года называлась «Schweizerhall», ведет свое начало с далекого 1836 года, когда недалеко от швейцарского города Базель был создан солеваренный завод. Впоследствии компания расширила свою деятельность и предложение продуктового портфеля, и стала одновременно заниматься как производством, так и торговлей химической продукции: кормов, минеральных удобрений и активных фармацевтических ингредиентов.',
        'В 1994 году компания становится публичной, выйдя на швейцарскую фондовую биржу (SIX: ACIN), и постепенно структурирует свой бизнес, фокусируясь на сфере производства химической, фармацевтической и биотехнологической продукции.',
        'В последующие годы компания расширила свой портфель за счет усовершенствованных лекарственных средств, приобретя в 2004 году компанию Cimex Pharma AG в швейцарском Лисберге – поставщика твердых лекарственных форм, и в 2006 году – компанию Novosis в городе Мисбах, Германия, – второго по размеру в Европе производителя трансдермальных пластырей и биоразлагаемых имплантатов.',
    ],
    // RiskRakaGrudi page
    RISK_RAKA_GRUDI_SLIDE_TEXT: [
        'По итогам прохождения теста вы получите информацию о вашем личном уровне риска возникновения рака молочной железы в течение ближайших пяти лет и на протяжении жизни. Также в результатах теста вы получите рекомендации по дальнейшим действиям. Женщинам с низким уровнем риска необходимо проходить стандартные ежегодные обследования у маммолога: до 50 лет делать УЗИ-диагностику молочных желёз, после 50 – проходить рентгеновскую маммографию, если нет отягощенного анамнеза.',
    ],
    // OurHistory section
    // Partners page
    HEADER_SLIDE_PARTNER_TITLE: 'Партнерам',
    HEADER_SLIDE_PARTNER_SUBTITLE:
        'В Acino мы стремимся предоставлять нашим клиентам и партнерам высококачественные продукты, но, что уникально, мы также стремимся предоставлять быстрое и индивидуальное обслуживание. И мы неизменно достигаем результатов там, где это самое важное.',
    OUR_PARTNERS_SECTION_TITLE: 'Наши партнеры',
    PARTNERS_SECTION_TITLE: 'Станьте нашим партнером',
    PARTNERS_SECTION_TEXT:
        'Мы быстро развиваемся за счет органического бизнеса, а также активно стремимся расширить наше портфолио посредством лицензирования для партнеров или путем слияния и поглощения компаний.',
    PARTNERS_SECTION_BUTTON: 'Стать партнером',
    // WeOffer section
    // 1
    WE_OFFER_SECTION_TITLE1: 'Лучшее партнерство',
    WE_OFFER_SECTION_SUBTITLE1:
        'Быстрое и индивидуальное обслуживание, а также наши высококачественные продукты гарантируют то, что мы станем вашим надежным и долгосрочным партнером, которому можно доверять.',
    // 2
    WE_OFFER_SECTION_TITLE2: 'Четкая цель',
    WE_OFFER_SECTION_SUBTITLE2:
        'Мы всегда действуем в соответствии с нашей стратегической целью – найти подход к каждому клиенту и предложить самое качественное решение, соответствующее законодательству и нормативно-правовой базе',
    WE_OFFER_SECTION_TITLE3: 'Проактивный подход',
    // 3
    WE_OFFER_SECTION_SUBTITLE3:
        'Мы всегда проактивны – находим беспроигрышные решения, которые не идут на компромисс по качеству или целостности, обнаруживаем возможности роста и создаем новые ценности.',
    WE_OFFER_SECTION_TITLE4: 'Обновления и рост',
    // 4
    WE_OFFER_SECTION_SUBTITLE4:
        'Мы добавляем новые молекулы и технологии в портфолио, особенно в стремительно развивающихся направлениях: сердечно-сосудистые заболевания, неврология, гастроэнтерология, общая терапия, гинекология.',
    // TeamPage
    HEADER_SLIDE_TEAM_TITLE: 'Наша команда',
    HEADER_SLIDE_TEAM_SUBTITLE:
        'Мы реализуем передовую практику корпоративного управления и постоянно ее совершенствуем, что формирует наше стремление к безупречному качеству во всем, что мы делаем.',
    // CareerPage
    HEADER_SLIDE_CAREER_TITLE: 'Карьера в Acino',
    HEADER_SLIDE_CAREER_SUBTITLE:
        'Мы всегда готовы принять в нашу большую семью сотрудников с высоким потенциалом и желанием внести свой вклад в развитие нашей компании.',
    HEADER_SLIDE_CAREER_BUTTON: 'Отправить резюме',
    // RiskRakaGrudiPage
    HEADER_SLIDE_RISKRAKAGRUDI_TITLE: 'Определение риска развития рака груди',
    HEADER_SLIDE_RISKRAKAGRUDI_SUBTITLE: 'Пройдите тест на определение риска возникновения рака молочной железы',
    HEADER_SLIDE_RISKRAKAGRUDI_BUTTON: 'Пройти тест',
    // CareerPage WeOffer section
    WE_OFFER_SECTION_CAREER_TITLE: 'Мы предлагаем',
    WE_OFFER_SECTION_CAREER_TEXT1: 'Комфортный офис',
    WE_OFFER_SECTION_CAREER_TEXT2: 'Рост и развитие',
    WE_OFFER_SECTION_CAREER_TEXT3: 'Система премирования',
    WE_OFFER_SECTION_CAREER_TEXT4: 'ДМС, страхование жизни',
    WE_OFFER_SECTION_CAREER_TEXT5: 'Cлужебный автомобиль',
    WE_OFFER_SECTION_CAREER_TEXT6: 'Корпоративное обучение',
    // CareerPage AboutUs section
    CAREER_PAGE_ABOUT_SECTION_TITLE: 'О компании',
    CAREER_PAGE_ABOUT_SECTION_TEXT: [
        'Acino Belarus – Белорусское подразделение международной фармацевтической группы Acino со штаб-квартирой в Цюрихе, Швейцария.',
        'Команда Acino разделяет ценности как врачей, так и пациентов по всему миру. Пациенты научили нас быть отважными и брать на себя обязательства, а врачи – сопереживать, доверять и не сдаваться.',
        'Мы в Acino живём стремлением сделать медицину доступной для тех, кто в этом нуждается. Поэтому коллектив Acino ставит своей целью стать брендом, который выбирают как пациенты, так и специалисты здравоохранения.',
    ],
    CAREER_PAGE_ABOUT_SECTION_BUTTON: 'Подробнее',
    CAREER_PAGE_VALANCIES_SECTION_TITLE: 'Актуальные вакансии',
    CAREER_PAGE_VALANCIES_SECTION_TEXT:
        'К сожалению, на данный момент нет актуальных вакансий. Пришлите нам свое резюме, и мы обязательно его рассмотрим!',
    CAREER_PAGE_VALANCIES_SECTION_BUTTON: 'Отправить резюме',
    // CurrentVacancies SectionBlock
    CURRENT_VACANCIES_TITLE: 'Актуальные вакансии',
    CURRENT_VACANCIES_BUTTON_TEXT: 'Подробнее',
    // ProductsPage
    PRODUCTS_LIST_PAGE_TITLE: 'Наши продукты',
    PRODUCTS_LIST_PAGE_SUBTITLE:
        'Важно: эта страница и вся размещенная на ней информация предназначены только для медицинских и фармацевтических работников.',
    ACINO_BELARUS: 'Acino Belarus',
    // ProductsPage categories
    PRODUCTS_LIST_PAGE_CATEGORY_DEFAULT: 'Все',
    PRODUCTS_LIST_PAGE_CATEGORY_RECIPY: 'Рецептурные',
    PRODUCTS_LIST_PAGE_CATEGORY_WITHOUT_RECIPY: 'Безрецептурные',
    PRODUCTS_LIST_PAGE_CATEGORY_DIETARY_SUPPLEMENTS: 'Бады',
    PRODUCTS_LIST_PAGE_PRODUCT_ITEM_BUTTON: 'Подробнее',
    // Promohub page
    PROMOHUB_SLIDE_CAREER_TITLE: 'PromoHub',
    PROMOHUB_SLIDE_CAREER_SUBTITLE:
        'Добро пожаловать в цифровую библиотеку Acino! Данная площадка была создана специально для медицинских специалистов. Здесь вы можете найти обучающие видеоролики, статьи, публикации по интересующим вас направлениям. Библиотека постоянно обновляется и расширяется. Следите за нашими новостями!',
    PROMOHUB_LIST_PAGE_INFO:
        'Важно: эта страница и вся размещенная на ней информация предназначены только для медицинских и фармацевтических работников.',
    PROMOHUB_LIST_PAGE_CATEGORY_DEFAULT: 'Публикации',
    PROMOHUB_LIST_PAGE_CATEGORY_VIDEO: 'Видео',
    PROMOHUB_LIST_PAGE_CATEGORY_WITHOUT_NEWS: 'Новости',
    // PopupPartners
    POPUP_PARTNERS_CONTENT_TITLE: 'Станьте нашим партнером',
    POPUP_PARTNERS_CONTENT_SUBTITLE: 'Заполните форму и мы свяжемся с вами в течении дня',
    POPUP_PARTNERS_CONTENT_INPUT_FIO: 'ФИО*',
    POPUP_PARTNERS_CONTENT_INPUT_COUNTRY: 'Город, страна*',
    POPUP_PARTNERS_CONTENT_INPUT_ORGANIZATION: 'Название организации*',
    POPUP_PARTNERS_CONTENT_INPUT_JOB: 'Должность контактного лица*',
    POPUP_PARTNERS_CONTENT_INPUT_EMAIL: 'E-mail*',
    POPUP_PARTNERS_CONTENT_INPUT_PHONE: 'Телефон*',
    POPUP_PARTNERS_CONTENT_INPUT_SUBMIT: 'Стать партнером',
    POPUP_PARTNERS_CONTENT_CONFIRMATION_TEXT:
        '*отправляя заявку, вы соглашаетесь с <a href="/cookie-policy" target="_blank">Политикой Конфиденциальности</a>',
    // PopupCareer
    POPUP_CAREER_CONTENT_TITLE: 'Станьте частью нашей команды',
    POPUP_CAREER_CONTENT_SUBTITLE: 'Заполните форму и мы свяжемся с вами в течении дня',
    POPUP_CAREER_CONTENT_INPUT_FIO: 'ФИО*',
    POPUP_CAREER_CONTENT_INPUT_EMAIL: 'E-mail*',
    POPUP_CAREER_CONTENT_INPUT_VACANCY: 'Название вакансии или должности*',
    POPUP_CAREER_CONTENT_INPUT_PHONE: 'Телефон*',
    POPUP_CAREER_CONTENT_INPUT_SUBMIT: 'Отправить',
    POPUP_CAREER_CONTENT_CONFIRMATION_TEXT:
        '*отправляя заявку, вы соглашаетесь с <a href="/cookie-policy" target="_blank">Политикой Конфиденциальности</a>',
    ACINO: 'Acino',
    ABOUT: 'О Нас',
    OFFICE: 'Офис',
    COOPERATION: 'Сотрудничество',
    // MEDIA: 'Промохаб',
    DESCRIPTION_TO: 'Подписаться',
    CARDIOLOGY_MENU: 'Болезни сердца',
    ENDOKRINOLOGIA_MENU: 'Эндокринология',
    GYNECOLOGY_MENU: 'Гинекология',
    GASTROENTOLOGY: 'Гастроэнтерология',
    NEUROLOGY_MENU: 'Hеврология',
    DERMOTOLOGY: 'Дерматология',
    EVENTS: 'Мероприятия',
    PUBLICS: 'Публикации',
    VIDEO: 'Видео',
    CATALOG: 'Наши Продукты',
    PANEL_LINK: 'Информационные ресурсы для специалистов занятых в сфере здравоохранения',
    VISION: 'Видение',
    VISION_TEXT: 'Быстрый рост и поддержка пациентов на рынках развивающихся стран',
    BANNER_TITLE: 'Ценности',
    BANNER_TITLE_2: 'Наша история',
    BANNER_SUBTITLE_1: 'Доверие:',
    BANNER_SUBTITLE_2: 'Приверженность:',
    BANNER_SUBTITLE_3: 'Отвага:',
    BANNER_SUBTITLE_4: 'Эмпатия:',
    BANNER_TEXT_1:
        'Твердо верить в надежность, истину и выполнять свои обязательства. Выражать уверенность. Быть верным. Действовать как командный игрок. Быть искренним.',
    BANNER_TEXT_2:
        'Ориентироваться на цель и результат. Выполнять обещание и быть ответственным. Проявлять личную вовлеченность. Достигать большего. Действовать, как владелец бизнеса. Фокусироваться на потребностях клиентов и пациентов.',
    BANNER_TEXT_3:
        'Ставить амбициозные цели и достигать их. Быть готовым к изменениям и выходить из зоны комфорта. Отстаивать свое мнение. Принимать вызовы. Рисковать.',
    BANNER_TEXT_4:
        'Быть открытым и проявлять уважение. Понимать и поддерживать других. Помогать и признавать вклад каждого. Слушать и слышать.',
    BANNER_TEXT_5:
        'Истоки компании Acino, которая до 2008 года называлась «Schweizerhall», относятся к 1836 году, когда компания занималась соляным бизнессом неподалеку от Базеля. Впоследствии компания расширила сферу деятельности и выступила одновременно в качестве производителя и дистрибьютора,  дополнив свой портфель высокотехнологичными продуктами, включая специальные химические продукты, такие как корма, удобрения и активные фармацевтические ингредиенты.',
    OUR_PRODUCTS: 'Наши продукты',
    DRUGS_TITLE: 'Мы работаем на благо сохранения здоровья',
    CARDIOLOGY: 'КАРДИОЛОГИЯ',
    THYROIDOLOGY: 'ТИРЕОИДОЛОГИЯ',
    DIABETOLOGY: 'ДИАБЕТОЛОГИЯ',
    GYNECOLOGY: 'ГИНЕКОЛОГИЯ',
    NEUROLOGY: 'НЕВРОЛОГИЯ И ПСИХИАТРИЯ',
    THERAPY: 'ОБЩАЯ ТЕРАПИЯ',
    ENDOKRINOLOGIA: 'ЭНДОКРИНОЛОГИЯ',
    KONKOR_KOR: 'КОНКОР® КОР',
    KONKOR: 'КОНКОР®',
    EUTIROKS: 'ЭУТИРОКС®',
    TIROZOL: 'ТИРОЗОЛ®',
    IODOBALSNS: 'ЙОДБАЛАНС®',
    GLUKOFAZ: 'ГЛЮКОФАЖ®',
    GLUKOVANS: 'ГЛЮКОВАНС®',
    TAJM_FACTIR: 'ТАЙМ-ФАКТОР®',
    ESTROVEL: 'ЭСТРОВЭЛ®',
    FERRETAB: 'ФЕРРЕТАБ®',
    RIZOPTAN: 'РИЗОПТАН®',
    ARIPROZOL: 'АРИПРАЗОЛ®',
    NEOGABIN: 'НЕОГАБИН®',
    LAMOTRIN: 'ЛАМОТРИН®',
    LEVOKOM: 'ЛЕВОКОМ®',
    METILPREDNIZOLON: 'МЕТИЛПРЕДНИЗОЛОН-ФС®',
    PARTNERSHIP: 'Сотрудничество',
    CAREER: 'Карьера',
    OFFERS: 'Предложения',
    CONTACTS_DETAIL: '(в том числе для сообщений о нежелательных реакциях на препараты)',
    CONTACTS_FILL_FORM:
        'Запросы по медицинской информации о препаратах Acino </br> Если у Вас есть научный или медицинский вопрос о препаратах Acino, заполните и отправьте форму',
    CONTACTS_FILL_FORM_BUTTON: 'Заполнить форму',
    ADRESS: 'г.Минск, пр. Победителей 106, офис 401 (помещение 34)',
    PHONE: 'тел: +375 17 319 91 42',
    FAX: 'факс: +375 17 319 91 40',
    EMAIL: 'e-mail: office_by@acino.swiss',

    BANNER_TITLE_HUB: 'PromoHub',
    BANNER_TEXT_HUB:
        'Добро пожаловать в цифровую библиотеку Acino! Данная площадка была создана специально для медицинских специалистов. Здесь вы можете найти обучающие видеоролики, статьи, публикации по интересующим вас направлениям: эндокринология, неврология, кардиология, гинекология, психиатрия. Библиотека постоянно обновляется и расширяется. Доступ к библиотеке абсолютно бесплатный. Следите за нашими новостями! ',
    SEARCH: 'Поиск',
    LATEST_NEWS: 'Последние новости',
    LATEST_VIDEO: 'Последние видео',
    LATEST_PUBLICATION: 'Последние публикации',
    MORE: 'Подробнее...',
    DIALOG_TITLE: 'Являетесь ли Вы специалистом здравоохранения?',
    DIALOG_TEXT: 'Информация размещенная далее является только для специалистов здравоохранения',
    DIALOG_TEXT2: 'Информационные ресурсы для специалистов здравоохранения',
    YES: 'Да',
    NO: 'Нет',
    REGISTRATION: 'Зарегистрироваться',
    CANCEL: 'Отмена',
    CATALOG_PDF_BUTTON: 'Скачать полную инструкцию по применению лекарственного средства ',
    CATALOG_PRICE_PHARM: 'Цены в аптеках:',
    CATALOG_ORDER_PHARM: 'Заказ в аптеке:',
    COOKIE_TITLE: 'Использование файлов Cookies',
    COOKIE_TEXT:
        'Посещая Калькулятор, Вы соглашаетесь на использование нами файлов cookie в соответствии с Политикой конфиденциальности (вставить ссылку на политику конфиденциальности, которая внизу файла). Корректная работа Калькулятора возможна только в случае использования всех файлов Cookie. Нажмите кнопку “Принять все файлы Cookie”, чтобы продолжить работу или закройте всплывающее окно, чтобы отклонить файлы Cookie. Вы можете изменить настройки файлов Cookie ',
    COOKIE_ACCEPT: 'Принять все файлы Cookie',
    COOKIE_TITLE2: 'Настройки файлов Cookies',
    COOKIE_SAVE_SETTINGS: 'Сохранить мои настройки',
    // Request Section
    REQUEST_TITLE: 'Запросы по медицинской информации о препаратах ',
    REQUEST_TEXT: 'Если у Вас есть научный или медицинский вопрос о препаратах Acino, заполните и отправьте форму',
    REQUEST_BUTTON: 'Заполнить форму',
    // Contacts Section
    CONTACTS_TITLE: 'Acino Belarus',
    CONTACTS_SUBTITLE: 'Контакты',
    CONTACTS_SUBTITLE2: 'Фармаконадзор',
    CONTACTS_SUBTITLE3: 'Контактные данные для информирования о нежелательных реакциях:',
    CONTACTS_CONTENT_TITLE1: 'Адрес:',
    CONTACTS_CONTENT_SUBTITLE1: 'г.Минск, пр. Победителей 106, офис 401 (помещение 34)',
    CONTACTS_CONTENT_TITLE2: 'E-mail:',
    CONTACTS_CONTENT_SUBTITLE2: 'office_by@acino.swiss',
    CONTACTS_CONTENT_TITLE3: 'Телефон:',
    CONTACTS_CONTENT_SUBTITLE3: '+375 (17) 319-91-42',
    CONTACTS_CONTENT_SUBTITLE4: '+375 (17) 319-19-42',
    CONTACTS_CONTENT_SUBTITLE5: 'Safety_By@acino.swiss',
    // Footer Section
    FOOTER_INFO: 'Швейцарская конфедерация, действующее через Представительство в Республике Беларусь ',
    FOOTER_ADRESS_LINK: 'г.Минск, пр. Победителей 106, офис 401 (помещение 34)',
};
