/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { content } from 'commons/content';
import { externalLink } from 'commons/constants';
import {
    UL,
    ExternalLinkBold,
    SubMenuLinkTo,
    Dropdown,
    DropdownContent,
    DropdownBtn,
    MenuBackground,
    MobMenu,
} from './MobileMenu.styles';
import ArrowSvg from '../Menu/ArrowSvg';
import { isTablet } from '../../../../commons/constants';
import { LogoBar } from '../styles';
import LogoHeader from '../LogoHeader';
import MenuCloseButton from '../Menu/MenuCloseButton/MenuCloseButton';

export default function MobileMenu({ open, changeOpenState }) {
    const location = useLocation();
    const [dropdownActive, setDropdownActive] = useState(false);

    useEffect(() => {
        const isActive = ['/our-values', '/our-history', '/team', '/career'].includes(location.pathname);
        setDropdownActive(isActive);
    }, [location]);

    const handleDropdownClick = () => {
        setDropdownActive(!dropdownActive);
    };

    const handleSubMenuClick = () => {
        changeOpenState();
        setDropdownActive(true);
    };
    return (
        <MobMenu open={open}>
            <MenuBackground onClick={changeOpenState} />
            <UL open={open}>
                <LogoBar>
                    <Link to="/">
                        <LogoHeader changeOpenState={changeOpenState} />
                    </Link>
                </LogoBar>
                <MenuCloseButton changeOpenState={changeOpenState} />
                <li>
                    <NavLink exact to="/" activeClassName="active" onClick={changeOpenState}>
                        {content.MENU_HEADER_HOME}
                    </NavLink>
                </li>
                <Dropdown>
                    <DropdownBtn onClick={handleDropdownClick} className={dropdownActive ? 'active' : ''}>
                        {content.MENU_HEADER_ABOUT}
                        <ArrowSvg />
                    </DropdownBtn>
                    <DropdownContent className={dropdownActive ? 'active' : ''}>
                        <SubMenuLinkTo to="/our-values" onClick={handleSubMenuClick} activeClassName="active">
                            {content.MENU_HEADER_PARTNERS_SUBHEADER_MISSION}
                        </SubMenuLinkTo>
                        <SubMenuLinkTo to="/our-history" onClick={handleSubMenuClick} activeClassName="active">
                            {content.MENU_HEADER_PARTNERS_SUBHEADER_HISTORY}
                        </SubMenuLinkTo>
                        <SubMenuLinkTo to="/team" onClick={handleSubMenuClick} activeClassName="active">
                            {content.MENU_HEADER_PARTNERS_SUBHEADER_TEAM}
                        </SubMenuLinkTo>
                        <SubMenuLinkTo to="/career" onClick={handleSubMenuClick} activeClassName="active">
                            {content.MENU_HEADER_PARTNERS_SUBHEADER_CAREER}
                        </SubMenuLinkTo>
                    </DropdownContent>
                </Dropdown>
                <li>
                    <NavLink to="/products" activeClassName="active" onClick={changeOpenState}>
                        {content.MENU_HEADER_DRUGS}
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/partners" activeClassName="active" onClick={changeOpenState}>
                        {content.MENU_HEADER_PARTNERS}
                    </NavLink>
                </li>
                {/* <li>
                    <NavLink to="/promohub" activeClassName="active" onClick={changeOpenState}>
                        {content.MENU_HEADER_PROMOHUB}
                    </NavLink>
                </li> */}
                <li>
                    <ExternalLinkBold
                        href={externalLink.PANEL}
                        target="_blank"
                        rel="noreferrer noopener"
                        onClick={changeOpenState}
                    >
                        {content.MENU_HEADER_SUBSCRIPTION}
                    </ExternalLinkBold>
                </li>
                <li>
                    <NavLink to="/contacts" activeClassName="active" onClick={changeOpenState}>
                        {content.MENU_HEADER_CONTACTS}
                    </NavLink>
                </li>
            </UL>
        </MobMenu>
    );
}

MobileMenu.propTypes = {
    open: PropTypes.bool.isRequired,
    changeOpenState: PropTypes.func.isRequired,
};
